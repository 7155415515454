import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import "./columnchart.scss";
import { useSelector } from "react-redux";
import moment from "moment";
import { STATUS_ORDER_ID } from "../../../_service/_const";
import { Skeleton } from "@mui/material";

const ColumnChart = ({ isLoading }) => {
  const { allOrder } = useSelector((state) => state.order);
  let yearsValaible = []; //year in every orders
  let yearsArray = []; //set of years included the currentYear

  //force reload
  // const [, updateState] = React.useState();
  // const forceUpdate = React.useCallback(() => updateState({}), []);
  // const [reducerReloadValue, forceReload] = React.useReducer((x) => x + 1, 0);
  // const [updateComp, SetUpdateComp] = React.useState(0);

  //get set of all the year included in the orders & the currentDate
  if (allOrder.length > 0) {
    for (let item of allOrder) {
      let y = moment(item.data).year();
      yearsValaible.push(y.toString());
    }
  }
  var now = moment();
  var currentYear = moment(now).year();
  yearsValaible.push(currentYear.toString());

  const yearSet = new Set(yearsValaible);
  yearsArray = Array.from(yearSet);

  // const [selectedYear, setselectedYear] = useState();
  const [selectedYear, setselectedYear] = useState(currentYear);
  //-----------------------------------------------
  //get the number of order by selected year year
  let ordersBySelectedYear = [];
  if (allOrder.length > 0) {
    allOrder.forEach((element) => {
      if (element.date.includes(selectedYear))
        ordersBySelectedYear.push(element);
    });
  }

  // console.log(selectedYear);
  // console.log(ordersBySelectedYear);

  //-----------------------------------------------
  //get the number of order per month of a selected year
  //months containing command & secondly filter orders which are sold
  let jan = [];
  let fev = [];
  let mar = [];
  let avr = [];
  let mai = [];
  let jun = [];
  let jui = [];
  let aou = [];
  let sep = [];
  let oct = [];
  let nov = [];
  let dec = [];
  //months containing command sold(commande reglées)
  let jan_sold = [];
  let fev_sold = [];
  let mar_sold = [];
  let avr_sold = [];
  let mai_sold = [];
  let jun_sold = [];
  let jui_sold = [];
  let aou_sold = [];
  let sep_sold = [];
  let oct_sold = [];
  let nov_sold = [];
  let dec_sold = [];

  //push data in month containing order&push data in month is those order are sold
  if (ordersBySelectedYear.length > 0) {
    for (let item of ordersBySelectedYear) {
      let month = moment(item.date).month();
      switch (month) {
        case 0:
          jan.push(item);
          if (item.statusCmd_id === STATUS_ORDER_ID.REGLE) jan_sold.push(item);
          break;
        case 1:
          fev.push(item);
          if (item.statusCmd_id === STATUS_ORDER_ID.REGLE) fev_sold.push(item);
          break;
        case 2:
          mar.push(item);
          if (item.statusCmd_id === STATUS_ORDER_ID.REGLE) mar_sold.push(item);
          break;
        case 3:
          avr.push(item);
          if (item.statusCmd_id === STATUS_ORDER_ID.REGLE) avr_sold.push(item);
          break;
        case 4:
          mai.push(item);
          if (item.statusCmd_id === STATUS_ORDER_ID.REGLE) mai_sold.push(item);
          break;
        case 5:
          jun.push(item);
          if (item.statusCmd_id === STATUS_ORDER_ID.REGLE) jun_sold.push(item);
          break;
        case 6:
          jui.push(item);
          if (item.statusCmd_id === STATUS_ORDER_ID.REGLE) jui_sold.push(item);
          break;
        case 7:
          aou.push(item);
          if (item.statusCmd_id === STATUS_ORDER_ID.REGLE) aou_sold.push(item);
          break;
        case 8:
          sep.push(item);
          if (item.statusCmd_id === STATUS_ORDER_ID.REGLE) sep_sold.push(item);
          break;
        case 9:
          oct.push(item);
          if (item.statusCmd_id === STATUS_ORDER_ID.REGLE) oct_sold.push(item);
          break;
        case 10:
          nov.push(item);
          if (item.statusCmd_id === STATUS_ORDER_ID.REGLE) nov_sold.push(item);
          break;
        case 11:
          dec.push(item);
          if (item.statusCmd_id === STATUS_ORDER_ID.REGLE) dec_sold.push(item);
          break;
        default:
          break;
      }
    }
  }

  // console.log(jan, fev, mar, avr, mai, jun, jui, aou, sep, oct, nov, dec);
  // console.log(
  // jan.length,
  // fev.length,
  // mar.length,
  // avr.length,
  // mai.length,
  // jun.length,
  // jui.length,
  // aou.length,
  // sep.length,
  // oct.length,
  // nov.length,
  // dec.length
  // );
  //-----------------------------------------------
  // console.log(
  // jan_sold.length,
  // fev_sold.length,
  // mar_sold.length,
  // avr_sold.length,
  // mai_sold.length,
  // jun_sold.length,
  // jui_sold.length,
  // aou_sold.length,
  // sep_sold.length,
  // oct_sold.length,
  // nov_sold.length,
  // dec_sold.length
  // );
  //-----------------------------------------------
  //state of our chart
  const state = {
    series: [
      {
        name: "Commande",
        // data: [43, 44, 55, 57, 56, 61, 58, 63, 60, 66, 45, 65],
        data: [
          jan.length,
          fev.length,
          mar.length,
          avr.length,
          mai.length,
          jun.length,
          jui.length,
          aou.length,
          sep.length,
          oct.length,
          nov.length,
          dec.length,
        ],
        color: "#7e222366",
      },
      {
        name: "Vente",
        // data: [65, 35, 41, 36, 26, 45, 48, 52, 53, 41, 33, 45],
        data: [
          jan_sold.length,
          fev_sold.length,
          mar_sold.length,
          avr_sold.length,
          mai_sold.length,
          jun_sold.length,
          jui_sold.length,
          aou_sold.length,
          sep_sold.length,
          oct_sold.length,
          nov_sold.length,
          dec_sold.length,
        ],
        color: "#54ed75",
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "60%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "Jan",
          "Fév",
          "Mar",
          "Avr",
          "Mai",
          "Jun",
          "Jul",
          "Aou",
          "Sep",
          "Oct",
          "Nov",
          "Déc",
        ],
      },
      yaxis: {
        title: {
          text: "Nombres",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            // return val + " poussins";
            return val;
          },
        },
      },
    },
  };

  // useEffect(() => {
  //   setstate({
  //     series: [
  //       {
  //         name: "Commande",
  //         // data: [43, 44, 55, 57, 56, 61, 58, 63, 60, 66, 45, 65],
  //         data: [
  //           jan.length,
  //           fev.length,
  //           mar.length,
  //           avr.length,
  //           mai.length,
  //           jun.length,
  //           jui.length,
  //           aou.length,
  //           sep.length,
  //           oct.length,
  //           nov.length,
  //           dec.length,
  //         ],
  //         color: "#7e222366",
  //       },
  //       {
  //         name: "Vente",
  //         // data: [65, 35, 41, 36, 26, 45, 48, 52, 53, 41, 33, 45],
  //         data: [
  //           jan_sold.length,
  //           fev_sold.length,
  //           mar_sold.length,
  //           avr_sold.length,
  //           mai_sold.length,
  //           jun_sold.length,
  //           jui_sold.length,
  //           aou_sold.length,
  //           sep_sold.length,
  //           oct_sold.length,
  //           nov_sold.length,
  //           dec_sold.length,
  //         ],
  //         color: "#54ed75",
  //       },
  //     ],
  //     options: {
  //       chart: {
  //         type: "bar",
  //         height: 350,
  //         toolbar: {
  //           show: false,
  //         },
  //       },
  //       plotOptions: {
  //         bar: {
  //           horizontal: false,
  //           columnWidth: "60%",
  //           endingShape: "rounded",
  //         },
  //       },
  //       dataLabels: {
  //         enabled: false,
  //       },
  //       stroke: {
  //         show: true,
  //         width: 2,
  //         colors: ["transparent"],
  //       },
  //       xaxis: {
  //         categories: [
  //           "Jan",
  //           "Fév",
  //           "Mar",
  //           "Avr",
  //           "Mai",
  //           "Jun",
  //           "Jul",
  //           "Aou",
  //           "Sep",
  //           "Oct",
  //           "Nov",
  //           "Déc",
  //         ],
  //       },
  //       yaxis: {
  //         title: {
  //           text: "Nombres",
  //         },
  //       },
  //       fill: {
  //         opacity: 1,
  //       },
  //       tooltip: {
  //         y: {
  //           formatter: function (val) {
  //             // return val + " poussins";
  //             return val;
  //           },
  //         },
  //       },
  //     },
  //   });
  // }, []);

  return (
    <>
      {isLoading ? (
        <div className="chartContainer">
          <div className="chartHeader_sk">
            <Skeleton width={250} height={30} variant="rectangular" />
            <Skeleton width={170} height={30} variant="rectangular" />
          </div>
          <Skeleton sx={{ width: "100%" }} height={400} variant="rectangular" />
        </div>
      ) : (
        <div className="chartContainer">
          <div className="chartHeader">
            {isLoading}
            <span className="title">Statistiques annuelles des ventes</span>
            <select
              name=""
              id=""
              value={selectedYear}
              onChange={(e) => setselectedYear(e.target.value)}
            >
              {yearsArray.map((value, index) => {
                return (
                  <option key={index} value={value}>
                    {value}
                  </option>
                );
              })}
            </select>
          </div>
          <ReactApexChart
            options={state.options}
            series={state.series}
            type="bar"
            height={350}
          />
        </div>
      )}
    </>
  );
};

export default ColumnChart;
