import React from "react";
import "./footer.scss";

let currentDate = new Date();
let year = currentDate.getFullYear();

const Footer = () => {
  return (
    <div className="footer_app">
      <span>INNOVAZING Vision DashBoard - © {year}</span>
      {/* <span>Ehula Technologie</span> */}
    </div>
  );
};

export default Footer;
