import React, { useContext } from "react";
import "./styles/global.scss";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import Client from "./pages/clients/Client";
import Store from "./pages/store/Store";
import Supplier from "./pages/supplier/Supplier";
import Login from "./pages/login/Login";
import Menu from "./components/menu/Menu";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import Dashboard from "./pages/dashboard/Dashboard";
import Order from "./pages/orders/Order";
import Sortie from "./pages/sorties/Sortie";
import SortieConfig from "./components/configuration/sortie/Sortie";
import Reservation from "./pages/reservations/Reservation";
import Messages from "./pages/messages/Messages";
import Notifications from "./pages/notifications/Notifications";
import Configuration from "./pages/configuration/Configuration";
import Profil from "./pages/profil/Profil";
import User from "./components/configuration/user/User";
import Category from "./components/configuration/category/Category";
import Rapport from "./components/notifications/rapport/Rapport";
import Requete from "./components/notifications/requete/Requete";
import ClientAll from "./components/client/all/ClientAll";
// import ClientPoussin from "./components/client/poussin/ClientPoussin";
// import ClientAliment from "./components/client/aliment/ClientAliment";
// import ClientBiogaz from "./components/client/biogaz/ClientBiogaz";
// import ClientChambreFroide from "./components/client/cf/ClientChambreFroide";
import SingleClient from "./pages/clients/SingleClient";
import OrderList from "./pages/ordersList/OrderList";
import OrderPoulet from "./components/orderList/poulet/OrderPoulet";
import OrderAliment from "./components/orderList/aliment/OrderAliment";
import OrderBiogaz from "./components/orderList/biogaz/OrderBiogaz";
import OrderPoussin from "./components/orderList/poussin/OrderPoussin";
import OrderAll from "./components/orderList/all/OrderAll";
import SingleOrder from "./pages/ordersList/SingleOrder";
import OrderSupplier from "./pages/orderssuppliers/OrderSupplier";
import OrderSupplierOeuf from "./components/ordersupplierList/oeuf/OrderSupplierOeuf";
import OrderSupplierList from "./pages/ordersupplierList/OrderSupplierList";
import OrderSupplierAll from "./components/ordersupplierList/all/OrderSupplierAll";
import OrderSupplierBiogaz from "./components/ordersupplierList/biogaz/OrderSupplierBiogaz";
import OrderSupplierAliment from "./components/ordersupplierList/aliment/OrderSupplierAliment";
import SingleOrderSupplier from "./pages/ordersupplierList/SingleOrderSupplier";
import SupplierAll from "./components/supplier/all/SupplierAll";
import SupplierOeuf from "./components/supplier/oeuf/SupplierOeuf";
import SupplierAliment from "./components/supplier/aliment/SupplierAliment";
import SupplierBiogaz from "./components/supplier/biogaz/SupplierBiogaz";
import SingleSupplier from "./pages/supplier/SingleSupplier";
import ReservationEnCours from "./components/reservation/encours/ReservationEnCours";
import ReservationSortie from "./components/reservation/sortie/ReservationSortie";
import SingleReservation from "./pages/reservations/SingleResersvation";
import Systeme from "./components/notifications/systeme/Systeme";
import Entree from "./pages/entrees/Entree";
import SaleList from "./components/sorties/sales/SaleList";
import AutreSortieList from "./components/sorties/autres/AutreSortieList";
import EntreeList from "./components/entrees/entrees/EntreeList";
import AutreEntreeList from "./components/entrees/autres/AutreEntreeList";
import NotFound from "./pages/404/NotFound";
import { AuthContext } from "./context/AuthContext";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import OrderOeuf from "./components/orderList/oeuf/OrderOeuf";
import OrderPoule from "./components/orderList/poule/OrderPoule";
import ClientPhysic from "./components/client/physic/ClientPhysic";
import ClientMoral from "./components/client/moral/ClientMoral";
import Incubation from "./pages/incubation/Incubation";
import IncubationAll from "./components/incubation/all/IncubationAll";
import Admin from "./components/configuration/admin/Admin";
import {
  ProtectedRouteFor_,
  ProtectedRouteFor_Admin_Log,
  ProtectedRouteFor_Admin_Log_Fin,
} from "./_service/WithPermission";
import Forbiden from "./pages/404/Forbiden";
import { USER_ROLE } from "./_service/_const";

type Props = {
  children: JSX.Element;
};

function App() {
  const { currentUser } = useContext(AuthContext);

  const RequireAuth = ({ children }: Props) => {
    return currentUser ? children : <Navigate to="/login" />;
    // return currentUser ? children : children;
  };

  const Layout = () => {
    return (
      <div className="main">
        <Navbar />
        <div className="container">
          <div className="menuContainer">
            <Menu />
          </div>
          <div className="contentContainer">
            <Outlet />
          </div>
        </div>
        <Footer />
      </div>
    );
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <RequireAuth>
          <Provider store={store}>
            <Layout />
          </Provider>
        </RequireAuth>
      ),
      children: [
        {
          path: "/",
          element: <Dashboard />,
        },
        {
          path: "clients",
          element: <Client />,
          children: [
            {
              path: "/clients",
              element: <ClientAll />,
            },
            {
              path: "/clients/cls-physic",
              element: <ClientPhysic />,
            },
            {
              path: "/clients/cls-moral",
              element: <ClientMoral />,
            },
            // {
            //   path: "/clients/cls-pss",
            //   element: <ClientPoussin />,
            // },
            // {
            //   path: "/clients/cls-alm",
            //   element: <ClientAliment />,
            // },
            // {
            //   path: "/clients/cls-bgz",
            //   element: <ClientBiogaz />,
            // },
            // {
            //   path: "/clients/cls-cf",
            //   element: <ClientChambreFroide />,
            // },
          ],
        },
        {
          path: "/clients/:id",
          element: <SingleClient />,
        },
        {
          path: "suppliers",
          element: (
            <ProtectedRouteFor_Admin_Log_Fin>
              <Supplier />
            </ProtectedRouteFor_Admin_Log_Fin>
          ),
          children: [
            {
              path: "/suppliers",
              element: <SupplierAll />,
            },
            {
              path: "/suppliers/fns-pss",
              element: <SupplierOeuf />,
            },
            {
              path: "/suppliers/fns-alm",
              element: <SupplierAliment />,
            },
            {
              path: "/suppliers/fns-bgz",
              element: <SupplierBiogaz />,
            },
          ],
        },
        {
          path: "suppliers/:id",
          element: <SingleSupplier />,
        },
        {
          path: "orders",
          element: <Order />,
        },
        {
          path: "/orders-suppliers",
          element: <OrderSupplier />,
        },
        {
          path: "/orders/list",
          element: <OrderList />,
          children: [
            {
              path: "/orders/list",
              element: <OrderAll />,
            },
            {
              path: "/orders/list/cmd-pss",
              element: <OrderPoussin />,
            },
            {
              path: "/orders/list/cmd-pls",
              element: <OrderPoule />,
            },
            {
              path: "/orders/list/cmd-bgz",
              element: <OrderBiogaz />,
            },
            {
              path: "/orders/list/cmd-alm",
              element: <OrderAliment />,
            },
            {
              path: "/orders/list/cmd-oef",
              element: <OrderOeuf />,
            },
            {
              path: "/orders/list/cmd-plt",
              element: <OrderPoulet />,
            },
          ],
        },
        {
          path: "/orders/:id",
          element: <SingleOrder />,
        },

        {
          path: "/orders-suppliers/list",
          element: <OrderSupplierList />,
          children: [
            {
              path: "/orders-suppliers/list",
              element: <OrderSupplierAll />,
            },
            {
              path: "/orders-suppliers/list/cmd-of",
              element: <OrderSupplierOeuf />,
            },
            {
              path: "/orders-suppliers/list/cmd-bgz",
              element: <OrderSupplierBiogaz />,
            },
            {
              path: "/orders-suppliers/list/cmd-alm",
              element: <OrderSupplierAliment />,
            },
          ],
        },
        {
          path: "/orders-suppliers/:id",
          element: <SingleOrderSupplier />,
        },
        {
          path: "/sortie",
          element: <Sortie />,
          children: [
            {
              path: "/sortie",
              element: <SaleList />,
            },
            {
              path: "/sortie/others",
              element: <AutreSortieList />,
            },
          ],
        },
        {
          path: "/entree",
          element: <Entree />,
          children: [
            {
              path: "/entree",
              element: <EntreeList />,
            },
            {
              path: "/entree/others",
              element: <AutreEntreeList />,
            },
          ],
        },
        {
          path: "store",
          element: (
            <ProtectedRouteFor_Admin_Log_Fin>
              <Store />
            </ProtectedRouteFor_Admin_Log_Fin>
          ),
        },
        {
          path: "messages",
          element: (
            <ProtectedRouteFor_Admin_Log>
              <Messages />,
            </ProtectedRouteFor_Admin_Log>
          ),
        },
        {
          path: "rapports",
          element: <Notifications />,
          children: [
            {
              path: "/rapports",
              element: <Rapport />,
            },
            {
              path: "/rapports/requetes",
              element: (
                <ProtectedRouteFor_Admin_Log>
                  <Requete />
                </ProtectedRouteFor_Admin_Log>
              ),
            },
            {
              path: "/rapports/systemes",
              element: (
                <ProtectedRouteFor_ roleRequired={USER_ROLE.ADMINISTRATION}>
                  <Systeme />
                </ProtectedRouteFor_>
              ),
            },
          ],
        },
        {
          path: "/incubation",
          element: (
            <ProtectedRouteFor_Admin_Log_Fin>
              <Incubation />
            </ProtectedRouteFor_Admin_Log_Fin>
          ),
          children: [{ path: "/incubation", element: <IncubationAll /> }],
        },
        {
          path: "reservations",
          element: (
            <ProtectedRouteFor_ roleRequired={USER_ROLE.ADMINISTRATION}>
              <Reservation />
            </ProtectedRouteFor_>
          ),
          children: [
            {
              path: "/reservations",
              element: <ReservationEnCours />,
            },
            {
              path: "/reservations/rvt-srt",
              element: <ReservationSortie />,
            },
          ],
        },
        {
          path: "/reservations/:id",
          element: <SingleReservation />,
        },
        {
          path: "configuration",
          element: <Configuration />,
          children: [
            {
              path: "/configuration",
              element: <User />,
            },
            {
              path: "/configuration/category",
              element: <Category />,
            },
            {
              path: "/configuration/administrateur",
              element: (
                <ProtectedRouteFor_ roleRequired="Admin">
                  <Admin />
                </ProtectedRouteFor_>
              ),
            },
            {
              path: "/configuration/sortie",
              element: <SortieConfig />,
            },
          ],
        },
        {
          path: "profil",
          element: <Profil />,
        },
        {
          path: "forbiden",
          element: <Forbiden />,
        },
      ],
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
