import React from "react";
import "./dashboard.scss";
import CardOne from "../../components/dashboard/card1/CardOne";
import CardTwo from "../../components/dashboard/card2/CardTwo";
import CardThree from "../../components/dashboard/card3/CardThree";
import CardFour from "../../components/dashboard/card4/CardFour";
// import ChartBox from "../../components/dashboard/chart/ChartBox";
import ListBox from "../../components/dashboard/list/ListBox";
import CircularChart from "../../components/dashboard/chart/CircularChart";
import ColumnChart from "../../components/dashboard/chart/ColumnChart";
import { useNavigate } from "react-router-dom";
import { ERROR_MESSAGE } from "../../_service/_const";
import { order_api } from "../../_service/order.Caller";
import { requestType } from "../../types/GlobalType";
import { useDispatch } from "react-redux";

import {
  addAlimentOrder,
  addAllOrder,
  addBiogazOrder,
  addOeufOrder,
  addPouleOrder,
  addPouletOrder,
  addPoussinOrder,
} from "../../redux/orderSlice/orderSlice";
import ConnexionBar from "../../components/errorComp/connexionBar";

const Dashboard = () => {
  const navigate = useNavigate();

  //state is loading start here with true as value cause there is a
  //settimeout before the fetch function to be executed
  const [state, setState] = React.useState<requestType>({
    isLoading: true,
    errorMessage: null,
  });

  const dispatch = useDispatch();

  //fetch allOrder: define here cause it will be used in three children components,
  //so we facilitate its heritance

  const fetchAllCommandeData = async () => {
    if (localStorage.getItem("user")) {
      setState({
        isLoading: true,
        errorMessage: null,
      });

      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      try {
        let [
          dataAliment,
          dataBio,
          dataOeuf,
          dataPoussin,
          dataPoule,
          dataPoulet,
        ] = await order_api.clientOrder.getAllByService(token);

        let axios_Fake_Objet = {
          config: null,
          data: { data: [], message: "Situation Commande is Empty" },
          status: 205,
          statusText: "OK",
        };
        if (dataAliment.data.data === undefined)
          dataAliment = Object.create(axios_Fake_Objet);

        if (dataBio.data.data === undefined)
          dataBio = Object.create(axios_Fake_Objet);

        if (dataOeuf.data.data === undefined)
          dataOeuf = Object.create(axios_Fake_Objet);

        if (dataPoussin.data.data === undefined)
          dataPoussin = Object.create(axios_Fake_Objet);

        if (dataPoule.data.data === undefined)
          dataPoule = Object.create(axios_Fake_Objet);

        if (dataPoulet.data.data === undefined)
          dataPoulet = Object.create(axios_Fake_Objet);

        const mergedData = [
          ...dataAliment.data.data,
          ...dataBio.data.data,
          ...dataOeuf.data.data,
          ...dataPoussin.data.data,
          ...dataPoule.data.data,
          ...dataPoulet.data.data,
        ];

        // console.log(mergedData);

        dispatch(addAllOrder(mergedData));
        dispatch(addPoussinOrder(dataPoussin.data.data));
        dispatch(addOeufOrder(dataOeuf.data.data));
        dispatch(addAlimentOrder(dataAliment.data.data));
        dispatch(addBiogazOrder(dataBio.data.data));
        dispatch(addPouleOrder(dataPoule.data.data));
        dispatch(addPouletOrder(dataPoulet.data.data));

        setState({ errorMessage: "", isLoading: false });
      } catch (error: any) {
        // console.log("This is error concurrent-------");
        // console.log(error);
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      }
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      fetchAllCommandeData();
    }, 2000);
  }, []);

  return (
    <>
      {state.errorMessage === ERROR_MESSAGE.networkError && <ConnexionBar />}
      <div className="dashboard">
        <div className="header">
          <span>Dashboard</span>
        </div>
        <div className="home">
          <div className="box box1">
            {/* for clients */}
            <CardOne />
          </div>
          <div className="box box3">
            {/* for all orders */}
            <CardThree isLoading={state.isLoading} />
          </div>
          <div className="box box4">
            {/* for oders */}
            <CardFour isLoading={state.isLoading} />
          </div>
          <div className="box box2">
            {/* for incubation */}
            <CardTwo />
          </div>
          <div className="box box_graph1">
            {/* <ChartBox /> */}

            <ColumnChart isLoading={state.isLoading} />
          </div>
          <div className="box box_graph2">
            <CircularChart isLoading={state.isLoading} />
          </div>
          {/* <div className="box box_list">
            <ListBox isLoading={state.isLoading} />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
