import React from "react";
import "./forbiden.scss";
import Logo from "../../assets/logo.jpg";
import { Link } from "react-router-dom";
const Forbiden = () => {
  return (
    <div className="page_Forbiden">
      <div className="left">
        <h2>Page introuvable</h2>
        <h1>404</h1>
        <Link to="/">
          <span>Revenir à la page d'accueil</span>
        </Link>
      </div>
    </div>
  );
};

export default Forbiden;
