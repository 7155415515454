import React, { useContext, useEffect } from "react";
import "./navbar.scss";
import Language from "@mui/icons-material/Language";
import Person from "@mui/icons-material/Person";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import Logout from "@mui/icons-material/Logout";
import Logo from "../../assets/logo.jpg";
import { Link, useNavigate } from "react-router-dom";
import {
  Avatar,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import Brightness5Outlined from "@mui/icons-material/Brightness5Outlined";
import { AuthContext } from "../../context/AuthContext";
import { RapportType, requestType } from "../../types/GlobalType";
import { rapport_api } from "../../_service/notification.Call";
import { ERROR_MESSAGE, STATUS_RAPPORT_ID } from "../../_service/_const";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  addallRapport,
  addRapportEtablieCount,
} from "../../redux/rapportSlice/rapportSlice";
import { capitalize } from "lodash";
import { BarChartOutlined, SmsFailedOutlined } from "@mui/icons-material";
import {
  ProtectedRouteFor_Admin_Log_Fin,
  ProtectedSectionForAdmin_Log,
  userRole,
} from "../../_service/WithPermission";

const Navbar = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { dispatch } = useContext<any>(AuthContext);
  const handleDisconnect = () => {
    dispatch({ type: "LOGOUT" });
  };

  const [data, setData] = React.useState<RapportType[]>([]);
  const [username, setusername] = React.useState<string>("Inconnue");
  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  // const [notificationNum, setNotificationNum] = React.useState<number>(0);
  const navigate = useNavigate();

  const dispatchState = useDispatch();

  const { allRapport, rapportEtablieCount } = useSelector(
    (state: RootState) => state.rapport
  );

  // setTimeout(() => {
  //   if (localStorage.getItem("user")) {
  //     const userLS = JSON.parse(localStorage.getItem("user") || '"');
  //     if (userLS.user1 !== null) {
  //       const email = userLS.user.email;
  //       setusername(email.split("@")[0]);
  //     }
  //   }
  // }, 1000);
  //FETCH ALL RAPPORT WITH THE AIM OF LOOKING FOR NUMBER OF RAPPORT ETABLI
  //WHICH WILL BE THE NUM OVER THE NOTIFICATION ICON
  const fetchAllRapport = async () => {
    if (localStorage.getItem("user")) {
      setState({
        isLoading: true,
        errorMessage: null,
      });

      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      try {
        let [data1, data2, data3, data4, data5, data6, data7] =
          await rapport_api.getAll(token);

        let axios_Fake_Objet = {
          config: null,
          data: { data: [], message: "Situation Rapport is Empty" },
          status: 205,
          statusText: "OK",
        };
        if (data1.data.data === undefined)
          data1 = Object.create(axios_Fake_Objet);

        if (data2.data.data === undefined)
          data2 = Object.create(axios_Fake_Objet);

        if (data3.data.data === undefined)
          data3 = Object.create(axios_Fake_Objet);

        if (data4.data.data === undefined)
          data4 = Object.create(axios_Fake_Objet);

        if (data5.data.data === undefined)
          data5 = Object.create(axios_Fake_Objet);

        if (data6.data.data === undefined)
          data6 = Object.create(axios_Fake_Objet);

        if (data7.data.data === undefined)
          data7 = Object.create(axios_Fake_Objet);

        const mergedData = [
          ...data1.data.data,
          ...data2.data.data,
          ...data3.data.data,
          ...data4.data.data,
          ...data5.data.data,
          ...data6.data.data,
          ...data7.data.data,
        ];
        setData(mergedData);
        dispatchState(addallRapport(mergedData));

        setState({ errorMessage: "", isLoading: false });
      } catch (error: any) {
        // console.log("This is error concurrent---Rapport");
        // console.log(error);
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      }
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  let rapportEstablishedNum: number = 0;
  if (allRapport.length > 0) {
    for (let item of data) {
      if (item.status_rapport_id === STATUS_RAPPORT_ID.ETABLI) {
        rapportEstablishedNum++;
      }
    }
  }
  dispatch(addRapportEtablieCount(allRapport));

  // console.log(rapportEtablieCount);
  // console.log(rapportEstablishedNum);

  useEffect(() => {
    setTimeout(() => {
      fetchAllRapport();
    }, 2000);
  }, []);

  return (
    <div className="navbar">
      <div className="logo">
        <img src={Logo} alt="logo" />
        <div className="appname">
          <span>ADMIN</span>
          <span>Dashboard</span>
        </div>
      </div>
      <>
        <div className="icons">
          <ProtectedSectionForAdmin_Log>
            {/* <Link to="/messages"> */}
            <Tooltip title="Notifications">
              <div className="message">
                <SmsFailedOutlined className="icon" />
                {/* <span>10</span> */}
              </div>
            </Tooltip>
            {/* </Link> */}
          </ProtectedSectionForAdmin_Log>

          {/* <ProtectedRouteFor_Admin_Log_Fin>
            <Link to="/rapports">
              <Tooltip title="Rapports">
                <div className="notification">
                  <BarChartOutlined className="icon" />
                  {rapportEstablishedNum !== 0 ? (
                    <span>{rapportEstablishedNum}</span>
                  ) : rapportEstablishedNum === 0 ? null : (
                    <span>{rapportEstablishedNum}</span>
                  )}
                </div>
              </Tooltip>
            </Link>
          </ProtectedRouteFor_Admin_Log_Fin> */}

          <div className="language">
            <Language className="langIcon" />
            {/* <span>{userRole()?.toUpperCase()}</span> */}
            <span>{"Admin"}</span>
          </div>

          <Tooltip title="Paramètre du compte">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <div className="user">
                <Person className="icon" />
                {/* <span>{capitalize(username)}</span> */}
                <ArrowDropDown />
              </div>
            </IconButton>
          </Tooltip>
        </div>
        <Menu
          className="setting_menu"
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <Link to="/profil">
            <MenuItem sx={{ fontSize: "small" }}>
              <Avatar />
              Profile
            </MenuItem>
          </Link>
          <Divider />

          <Link to="/configuration">
            <MenuItem sx={{ fontSize: "small" }}>
              <ListItemIcon>
                <Brightness5Outlined fontSize="small" />
              </ListItemIcon>
              <ProtectedSectionForAdmin_Log message="Catalogue">
                Configuration
              </ProtectedSectionForAdmin_Log>
            </MenuItem>
          </Link>
          {/* <Link to="/configuration">
          <MenuItem sx={{ fontSize: "small" }}>
            <ListItemIcon>
              <History fontSize="small" />
            </ListItemIcon>
            Activités
          </MenuItem>
        </Link>
        <Divider /> */}
          {/* <MenuItem onClick={handleDisconnect} sx={{ fontSize: "small" }}> */}
          <MenuItem sx={{ fontSize: "small" }}>
            <Link to="/login">
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Déconnexion
            </Link>
          </MenuItem>
        </Menu>
      </>
    </div>
  );
};

export default Navbar;
