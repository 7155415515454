import React from "react";
import "./notfound.scss";
import Logo from "../../assets/logo.jpg";
import { Link } from "react-router-dom";
const NotFound = () => {
  return (
    <div className="page_notFound">
      <div className="left">
        <h2>Page non trouvé</h2>
        <h1>404</h1>
        <Link to="/login">
          <span>Revenir à la page d'accueil</span>
        </Link>
      </div>
      <div className="right">
        <img src={Logo} alt="Nacgritch_logo" />
      </div>
    </div>
  );
};

export default NotFound;
